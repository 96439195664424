import React from "react";

import myImage from "../images/arrow.gif";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";

import { useState, useEffect } from "react";
const momenttz = require("moment-timezone");

const GameResult = ({ dayGameData }) => {
  const currentTime = moment().format("HH:mm");
  const currentDate = moment().format("YYYY-MM-DD");

  const [data, setGameData] = useState([]);

  useEffect(() => {
    setGameData(dayGameData);
  }, [dayGameData]);

  // based on current date and time get data
  const getTodayResult = (gameData) => {
    const itemTime = moment(gameData.open_time, "HH:mm");
    const currentMoment = moment(currentTime, "HH:mm");

    if (gameData?.curr_date?.date === currentDate) {
      return currentMoment.isSameOrAfter(itemTime)
        ? gameData?.curr_date?.result || ""
        : "";
    }
    return "";
  };

  return (
    <div className="col-12">
      <div className="row">
        {data && data.length > 0 ? (
          data.map((gameData, index) => (
            <div
              key={index}
              className={`col-md-${
                index === 0 || index === 7 || index === 14 ? 12 : 6
              } col-sm-12 game_column`}
            >
              <div className="d-flex align-items-center flex-column">
                <h6 className="mb-0 pt-2 fw-bold fs-6">
                  {gameData?.game_name}
                </h6>
                <p className="mb-0 fs-6 textColor">( {gameData?.open_time} )</p>
                <div className="d-flex align-items-end text-center">
                  <div>
                    <span className="btn">
                      {gameData?.prev_date?.result || " "}
                    </span>
                  </div>
                  <div>
                    <img src={myImage} alt="updateimg"></img>
                  </div>
                  <div>
                    [<span className="btn">{getTodayResult(gameData)}</span>]
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default GameResult;
