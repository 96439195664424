import { Table } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";


function Monthlytable({ gamedata, dropValue }) {
  const [data, setData] = useState([]);
  let [columns, setcolumn] = useState([]);
  var date = new Date();

  const currentMonth = moment().tz("Asia/Kolkata").month();
  const currentYear = moment().tz("Asia/Kolkata").year();
  const currentMonthDays = moment().tz("Asia/Kolkata").daysInMonth();
  const [gameResult, setGameResult] = useState([]);
  useEffect(() => {
    if (gamedata) {
      setData(gamedata);
    }
  }, [gamedata]);
  var selectMonthDrop;
  useEffect(() => {
    if (dropValue) {
      setGameResult(dropValue);

      if (gameResult == "") {
        selectMonthDrop = moment().tz("Asia/Kolkata").format("MMMM");
        // console.log('if======>', selectMonthDrop)
      } else {
        selectMonthDrop = moment(
          `${dropValue?.year}-${dropValue?.month}-01`
        ).format("MMMM");
        // console.log('else======>', selectMonthDrop)
      }
      // console.log(gameResult);
      // console.log()
    } else {
    }
  });
  useEffect(() => {
    if (data.length > 0) {
      let array = Object.keys(data[0]);
      for (let i = 0; i < array.length; i++) {
        array[i] = {
          title: array[i] === "day" ? selectMonthDrop : array[i],
          dataIndex: array[i],
          key: array[i],
        };
      }
      setcolumn(array);
    }
  }, [data]);
  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST", // or 'PUT' depending on your requirements
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify({
        month: currentMonth + 1,
        year: currentYear,
        gameName: "",
        result: "",
        days: currentMonthDays,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="monthYrTbl pt-3" id="monthTable">
      <div className="table-responsive sattaBlack" id="scrollbar1">
        {!(dropValue == null) && (
          <h3
            className="text-center p-3 themecolor1"
            style={{ display: "block" }}
          >
            {dropValue?.gameName} MONTHLY RECORD CHART {selectMonthDrop} -{" "}
            {dropValue?.year || currentYear}
          </h3>
        )}
        <div className="tableStyle table-responsive">
          <Table dataSource={data} columns={columns} pagination={false} />
        </div>
      </div>
    </div>
  );
}

export default Monthlytable;
